import React from 'react'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

const NotFoundPage = (props) => (
  <Layout>
    <Helmet
        title="Daily Spikes - ERROR 404 "
        meta={[
            { name: 'description', content: '' },
            { name: 'keywords', content: ', , ' },
        ]}
    >
    </Helmet>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner" style={{margin:'0 auto',textAlign:'center'}}>
                <h1>ERROR 404</h1>
                <p>You&#39;ve found Patricia! But not the page you were looking for.</p>
                <div style={{width:'30%',margin:'0 auto'}}>
                <Img fluid={props.data.fileName.childImageSharp.fluid} alt="Error 404" />  
                </div>
            </div>
        </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "assets/images/404-pin-up.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default NotFoundPage
